import React from "react";
import "./artiset.css";
import ApoiosList from "./ApoiosList";
import { Main_Menu_Options_Translate } from "./configs/MenuBarConfig.js";

class Apoios extends React.Component {
  render(props) {
    return (
      <div id="Apoios" className="ContentFrameLight">
        <div className="ContentTitle">
          {Main_Menu_Options_Translate["MainApoios"][this.props.language]}
        </div>
        <div className="ContentContentRow">
          <ApoiosList />
        </div>
      </div>
    );
  }
}

export default Apoios;
