import React from "react";
import "./artiset.css";

import HomeDestaque from "./HomeDestaque";

import AtividadesEventos from "./AtividadesEventos";
import AtividadesFormacao from "./AtividadesFormacao";
import AtividadesProtocolos from "./AtividadesProtocolos";
import { Atividades_Menu_Options_Translate } from "./configs/MenuBarConfig.js";

class MainAtividades extends React.Component {
  render(props) {
    return (
      <div
        id="MainAtividades"
        className="ContentOuterFrameColumn"
        style={
          this.props.showMenu === "MainAtividades"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <div className="ContentFixHeaderMenu"></div>
        <div className="ContentOuterFrameRow">
          <div className="ContentSideMarginLeft"></div>

          <div className="ContentMidle">
            <HomeDestaque language={this.props.language} />

            <AtividadesFormacao
              menu_name="Formacao"
              language={this.props.language}
              menu_options_translate={Atividades_Menu_Options_Translate}
            />
            <AtividadesEventos
              menu_name="Eventos"
              language={this.props.language}
              menu_options_translate={Atividades_Menu_Options_Translate}
            />
            <AtividadesProtocolos
              menu_name="Protocolos"
              language={this.props.language}
              menu_options_translate={Atividades_Menu_Options_Translate}
            />
          </div>
          <div className="ContentSideMarginRight"></div>
        </div>
      </div>
    );
  }
}

export default MainAtividades;
