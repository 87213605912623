import React from "react";
import "./artiset.css";
import { Quem_Somos_Translate } from "./configs/QuemSomosConfig.js";
import ArtisetLargeLogo from "./configs/images/imgArtisetLargeLogo.jpg";
import { Home_Main_Content } from "./configs/HomeMainConfig";

function GetTrans(props) {
  return Home_Main_Content[props.text][props.language];
}

class QuemSomos extends React.Component {
  render(props) {
    return (
      <div id="QuemSomos" className="ContentFrameLight">
        <div className="ContentTitle"> </div>
        {/***************** */}
        <div className="ContentContentColumn">
          <div className="ContentContentRow">
            {/*<div className="HomeArtisetFrame">*/}

            <div className="artisetLogoFrame">
              <img className="artisetLogo" src={ArtisetLargeLogo} alt="" />
            </div>
            {/*<div>*/}
            <div className="artisetCardFrame">
              <div className="artisetCard">
                <p>
                  <GetTrans
                    language={this.props.language}
                    text={"HomeMainIntro1"}
                  />
                  <span className="artisetCardEnhance">
                    <GetTrans
                      language={this.props.language}
                      text={"HomeMainName"}
                    />
                  </span>
                  <GetTrans
                    language={this.props.language}
                    text={"HomeMainIntro2"}
                  />
                </p>
              </div>
              {/*</div>*/}
            </div>
            {/* </div>*/}
          </div>
          {/*************** */}

          <div className="ContentContentColumn">
            <p>{Quem_Somos_Translate["QuemSomosUm"][this.props.language]}</p>
            <p>{Quem_Somos_Translate["QuemSomosDois"][this.props.language]}</p>
            <p>
              {Quem_Somos_Translate["QuemSomosTres"][this.props.language]}{" "}
              <br></br>
              {Quem_Somos_Translate["QuemSomosQuatro"][this.props.language]}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default QuemSomos;
