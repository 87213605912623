import React from "react";
import "./artiset.css";
import { Atividades_Formacao_Translate } from "./configs/AtividadesFormacaoConfig";

class AtividadesFormacao extends React.Component {
  render(props) {
    return (
      <div id="AtividadesFormacao" className="ContentFrameLight">
        <div className="ContentTitle">
          {
            this.props.menu_options_translate[this.props.menu_name][
              this.props.language
            ]
          }
        </div>
        <div className="ContentContentRow">
          <div className="AtividadesFormacaoCard">
            <span className="FormacaoCardTitle">
              {/*<FaChalkboardTeacher />
              &nbsp;&nbsp;*/}
              {
                Atividades_Formacao_Translate["Formacao1"]["FormCardTitle"][
                  this.props.language
                ]
              }
            </span>
            <br></br>
            <div className="FormacaoCardContent">
              <span className="FormacaoCardText">
                {
                  Atividades_Formacao_Translate["Formacao1"]["FormCardText1"][
                    this.props.language
                  ]
                }
              </span>
              <br></br>
              <span>
                {
                  Atividades_Formacao_Translate["Formacao1"]["FormCardText2"][
                    this.props.language
                  ]
                }
              </span>
              <br></br>
              <span className="FormacaoCardHorario">
                {
                  Atividades_Formacao_Translate["Formacao1"]["FormCardHorario"][
                    this.props.language
                  ]
                }
              </span>
              <br></br>
              {
                Atividades_Formacao_Translate["Formacao1"]["FormCardContato"][
                  this.props.language
                ]
              }
            </div>
          </div>
          <div className="AtividadesFormacaoCard">
            <span className="FormacaoCardTitle">
              {/*} <FaChalkboardTeacher />
              &nbsp;&nbsp;*/}
              {
                Atividades_Formacao_Translate["Formacao2"]["FormCardTitle"][
                  this.props.language
                ]
              }
            </span>
            <br></br>
            <div className="FormacaoCardContent">
              <span className="FormacaoCardText">
                {
                  Atividades_Formacao_Translate["Formacao2"]["FormCardText1"][
                    this.props.language
                  ]
                }
              </span>
              <br></br>
              <span>
                {
                  Atividades_Formacao_Translate["Formacao2"]["FormCardText2"][
                    this.props.language
                  ]
                }
              </span>
              <br></br>
              <span className="FormacaoCardHorario">
                {
                  Atividades_Formacao_Translate["Formacao2"]["FormCardHorario"][
                    this.props.language
                  ]
                }
              </span>
              <br></br>
              {
                Atividades_Formacao_Translate["Formacao2"]["FormCardContato"][
                  this.props.language
                ]
              }
            </div>
          </div>

          <div className="AtividadesFormacaoCard">
            <span className="FormacaoCardTitle">
              {/* <FaChalkboardTeacher />*/}
              &nbsp;&nbsp;
              {
                Atividades_Formacao_Translate["Formacao3"]["FormCardTitle"][
                  this.props.language
                ]
              }
            </span>
            <br></br>
            <div className="FormacaoCardContent">
              <span className="FormacaoCardText">
                {
                  Atividades_Formacao_Translate["Formacao3"]["FormCardText1"][
                    this.props.language
                  ]
                }
              </span>
              <br></br>
              <span>
                {
                  Atividades_Formacao_Translate["Formacao3"]["FormCardText2"][
                    this.props.language
                  ]
                }
              </span>
              <br></br>
              <span className="FormacaoCardHorario">
                {
                  Atividades_Formacao_Translate["Formacao3"]["FormCardHorario"][
                    this.props.language
                  ]
                }
              </span>
              <br></br>
              {
                Atividades_Formacao_Translate["Formacao3"]["FormCardContato"][
                  this.props.language
                ]
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AtividadesFormacao;
