import React from "react";
import "./artiset.css";
import { Atividades_Protocolos_Translate } from "./configs/AtividadesProtocolosConfig.js";

class AtividadesProtocolos extends React.Component {
  render(props) {
    return (
      <div id="AtividadesProtocolos" className="ContentLightDark">
        <div className="ContentTitle">
          {
            this.props.menu_options_translate[this.props.menu_name][
              this.props.language
            ]
          }
        </div>
        <div className="ContentContentRowWrap">
          <div className="ProtocolosFormacaoCard">
            {/* <div className="ContentSubTitle">
              {
                Atividades_Protocolos_Translate["Protocolo3"]["ProtCardTitle"][
                  this.props.language
                ]
              }
            </div>*/}

            <div className="ContentContentColumn">
              <img
                className="ProtocoloImage"
                src={
                  Atividades_Protocolos_Translate["Protocolo3"][
                    "ProtCardImage"
                  ][this.props.language]
                }
                alt=""
              />
              {/*<div>
                {
                  Atividades_Protocolos_Translate["Protocolo1"][
                    "ProtCardText1"
                  ][this.props.language]
                }

                {
                  Atividades_Protocolos_Translate["Protocolo1"][
                    "ProtCardText2"
                  ][this.props.language]
                }
              </div>*/}
            </div>
          </div>
          <div className="ProtocolosFormacaoCard">
            {/*<div className="ContentSubTitle">
              {
                Atividades_Protocolos_Translate["Protocolo1"]["ProtCardTitle"][
                  this.props.language
                ]
              }
            </div>*/}

            <div className="ContentContentColumn">
              <img
                className="ProtocoloImage"
                src={
                  Atividades_Protocolos_Translate["Protocolo1"][
                    "ProtCardImage"
                  ][this.props.language]
                }
                alt=""
              />
              {/*<div>
                {
                  Atividades_Protocolos_Translate["Protocolo2"][
                    "ProtCardText1"
                  ][this.props.language]
                }

                {
                  Atividades_Protocolos_Translate["Protocolo2"][
                    "ProtCardText2"
                  ][this.props.language]
                }
              </div>*/}
            </div>
          </div>

          <div className="ProtocolosFormacaoCard">
            {/* <div className="ContentSubTitle">
              {
                Atividades_Protocolos_Translate["Protocolo2"]["ProtCardTitle"][
                  this.props.language
                ]
              }
            </div>*/}

            <div className="ContentContentColumn">
              <img
                className="ProtocoloImage"
                src={
                  Atividades_Protocolos_Translate["Protocolo2"][
                    "ProtCardImage"
                  ][this.props.language]
                }
                alt=""
              />
              {/*<div>
                {
                  Atividades_Protocolos_Translate["Protocolo3"][
                    "ProtCardText1"
                  ][this.props.language]
                }

                {
                  Atividades_Protocolos_Translate["Protocolo3"][
                    "ProtCardText2"
                  ][this.props.language]
                }
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AtividadesProtocolos;
