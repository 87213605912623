export const QuemSomos_Contatos_Translate = {
  QuemSomos_Contatos_Title: {
    PT: "Contatos",
    GB: "Contacts",
    FR: "Contacts",
    ES: "Contactos",
  },
  QuemSomos_Contatos_Text1: {
    PT: "A Artiset é parceiro da Casa da Cultura e tem a sua sede nas suas instalações.",
    GB: "Artiset is a partner of Casa da Cultura and is headquartered on your premises.",
    FR: "Artiset est partenaire de Casa da Cultura et a son siège dans ses installations.",
    ES: "A Artiset é parceiro da Casa da Cultura y tem a sua sede nas suas instalações.",
  },
  QuemSomos_Contatos_Text2: {
    PT: "Pode contatar-nos através de:",
    GB: "You can contact us through:",
    FR: "Vous pouvez nous contacter via:",
    ES: "Puede ponerse en contacto con nosotros a través de:",
  },

  QuemSomos_Contatos_Telephone: {
    PT: "+351 918928573",
    GB: "+351 918928573",
    FR: "+351 918928573",
    ES: "+351 918928573",
  },
  QuemSomos_Contatos_Email: {
    PT: "geral.artiset@gmail.com",
    GB: "geral.artiset@gmail.com",
    FR: "geral.artiset@gmail.com",
    ES: "geral.artiset@gmail.com",
  },
  QuemSomos_Contatos_Address1: {
    PT: "Casa da Cultura de Setúbal",
    GB: "Casa da Cultura de Setúbal",
    FR: "Casa da Cultura de Setúbal",
    ES: "Casa da Cultura de Setúbal",
  },
  QuemSomos_Contatos_Address2: {
    PT: "Artiset 1º piso",
    GB: "Artiset 1º piso",
    FR: "Artiset 1º piso",
    ES: "Artiset 1º piso",
  },
  QuemSomos_Contatos_Address3: {
    PT: "Rua Detrás da Guarda, nº 28",
    GB: "Rua Detrás da Guarda, nº 28",
    FR: "Rua Detrás da Guarda, nº 28",
    ES: "Rua Detrás da Guarda, nº 28",
  },
  QuemSomos_Contatos_Address4: {
    PT: "2900-0347 Setúbal",
    GB: "2900-0347 Setúbal",
    FR: "2900-0347 Setúbal",
    ES: "2900-0347 Setúbal",
  },
};
