import ExposicaoImg1 from "./images/EXP_Recomeças passo lento 1 a 14 Julho 2022.jpg";
import ExposicaoImg2 from "./images/EXP_AAAGP Exposição coletiva Artiset 2 a 24 Julho 2022.jpg";
import ExposicaoImg3 from "./images/EXP_Inseparáveis 16 a 28 Jul 2022.jpg";
import ExposicaoImg4 from "./images/EXP_Marsia 30 jul a 11 ago 2022.jpg";
import ExposicaoImg5 from "./images/EXP_Cores de mim 13 a 15 Ago 2022.JPG";
import ExposicaoImg6 from "./images/EXP_EntreRostos_27Ago08Set2022.JPG";
import ExposicaoImg7 from "./images/EXP_Com Alma 18 a 30 Jun 2022.jpg";
import ExposicaoImg8 from "./images/EXP_Arte Setubal 2022 05MAio a 24Junho2022.png";
import ExposicaoImg9 from "./images/EXP_Centenário de Saramago 4 a 16 Junho.jpg";
import ExposicaoImg10 from "./images/EXP_Alunos Finalistas Bocage 21Maio 2Junho 2022.jpg";
import ExposicaoImg11 from "./images/EXP_Reflexões Setúbal Cidade Criativa 22Maio2022.jpg";
import ExposicaoImg12 from "./images/EXP_ARTE E TERAPIA 8 Maio2022.png";
import ExposicaoImg13 from "./images/EXP_Carmo Candeias 7 a 19 Maio2022.jpg";
import ExposicaoImg14 from "./images/EXP_Retalhos da Vida de Um Foografo 23 Abril a 5 Maio2022.jpg";
import ExposicaoImg15 from "./images/EXP_Mourisca_05Feva27Mar2022.jpg";
import ExposicaoImg16 from "./images/EXP_CoisasDaMinhaTerra_fevMar2022.jpg";
import ExposicaoImg17 from "./images/EXP_Essencia_MR_17a19Out21.jpg";
import ExposicaoImg18 from "./images/EXP_Arte Setubal 2022 10a22Set2022.jpg";
import ExposicaoImg19 from "./images/EXP_Sopa Artistas_26Set a 6Out 2022.png";
import ExposicaoImg20 from "./images/EXP_Sem Lagrimas_8 a 20Out 2022.png";
import ExposicaoImg21 from "./images/EXP_Gentes e estados espirito_22Out a 3Nov 2022.png";
import ExposicaoImg22 from "./images/EXP_Jose Luis Aguiar_4 a 28Out 2022.png";
import ExposicaoImg23 from "./images/EXP_Reencontros 5 a 17Nov2022.jpg";
import ExposicaoImg24 from "./images/EXP_Sinais de Fogo 19Nov a 1Dez2022.jpg";
import ExposicaoImg25 from "./images/EXP_Retratos 3Dez a 5Jan2022.jpg";
import ExposicaoImg26 from "./images/EXP_Gordillo 60 anos 1 a 31Dez2022.jpg";
import ExposicaoImg27 from "./images/EXP_Entre Mundos e Ideias_7 a 19 Jan2023.jpg";
import ExposicaoImg28 from "./images/EXP_Olha Para Mim_21Jan a 2Fev2023.jpg";
import ExposicaoImg29 from "./images/EXP_Incursao no Abstrato_26Jan a 25Fev 2023.jpg";
import ExposicaoImg30 from "./images/EXP_Sobreviver_4 a 16 Mar2023.jpg";
import ExposicaoImg31 from "./images/EXP_Momentos_18 Fev a 2 Mar2023.jpg";
import ExposicaoImg32 from "./images/EXP_A arte de ser visto_1 a 16 Fev2023.jpg";
import ExposicaoImg33 from "./images/EXP_TheColorsOfPortugal_1a13Abril2023.jpg";
import ExposicaoImg34 from "./images/EXP_AsCoresdaAgua_15a27Abril2023.jpg";
import ExposicaoImg35 from "./images/EXP_UnitedPhotoPress_18a30Marco2023.png";
import ExposicaoImg36 from "./images/EXP_Agua Bem Essencial IPS_8a30Maio2023.jpg";
import ExposicaoImg37 from "./images/EXP_32Aniversario Artiset_20Maio2023.jpg";
import ExposicaoImg38 from "./images/EXP_Cidade Invisiveis_27Maio 8Junho 2023.jpg";
import ExposicaoImg39 from "./images/EXP_1ConcursoEscultura_2a3Jun2023.jpg";
import ExposicaoImg40 from "./images/EXP_Festaco_23Juna8Jul2023.jpg";
import ExposicaoImg41 from "./images/EXP_Ha Festa no  Parque_23Juna8Jul2023.jpg";
import ExposicaoImg42 from "./images/EXP_AtencaoArteExposta_13a15Maio2023.jpg";

import EventosComingSoon from "./images/artisetComingSoon.png";

export const Eventos_Archive_External = {
  text: {
    PT: "Consulte o nosso histórico de eventos anteriores",
    GB: "See our history of past events",
    FR: "Voir notre historique des événements passés",
    ES: "Vea nuestra historia de eventos pasados",
  },
  text_link: {
    PT: "aqui",
    GB: "here",
    FR: "ici",
    ES: "aquí",
  },
};

export const ExposicoesList = [
  {
    id: 1,
    title: "Recomeços a passo lento",
    subtitle: "",
    text: "Exposição de associados de Paço D’Artes, associação de  artes plásticas de Paço de Arcos, com o tema: Recomeços a passo lento.",
    highlight: "",
    when: "01 a 14 Julho 2022",
    date_begin: { year: 2022, month: 7, day: 1 },
    date_end: { year: 2022, month: 7, day: 14 },
    where: "Espaço das Artes - Casa Cultura Setúbal",
    horario: "3ªf a Domingo - 16:00 às 19:00",
    Contact: "",
    image: ExposicaoImg1,
    link: "",
    scrollType: "",
  },
  {
    id: 2,
    title: "ARTE SETÚBAL 2022",
    subtitle: "Pintura - Escultura - Fotografia",
    text: "Exposição coletiva Aritset 'Arte Setúbal 2022', no âmbito da parceria existente, a AAAGP recebe esta exposição coletiva da Artiset em duas galerias: a Galeria AAAGP e a TUA Galeria, em Buarcos, Figueira da Foz.",
    highlight: "",
    when: "2 a 24 Julho0 2022",
    date_begin: { year: 2022, month: 7, day: 2 },
    date_end: { year: 2022, month: 7, day: 24 },
    horario: "",
    where: "Buarcos - Figueira da Foz: Galeria AAAGP e TUA Galeria",
    Contact: "",
    image: ExposicaoImg2,
    link: "",
    scrollType: "",
  },
  {
    id: 3,
    title: "Inseparáveis",
    subtitle: "Exposição de Artes Plásticas",
    text: "Exposição de artes plástivas com obras de Aníbal Madeira, Fernando Araújo e Madureira Pais",
    highlight: "",
    when: "16 a 28 Julho 2022",
    date_begin: { year: 2022, month: 7, day: 16 },
    date_end: { year: 2022, month: 7, day: 28 },
    where: "Espaço das Artes - Casa Cultura Setúbal",
    horario: "3ªf a Domingo - 16:00 às 19:00",
    Contact: "",
    image: ExposicaoImg3,
    link: "",
    scrollType: "",
  },
  {
    id: 4,
    title: "Marsia",
    subtitle: "Nanci Braun",
    text: "Exposição de pintura de Nanci Braun",
    highlight: "",
    when: "30 Julho a 11 Agosto 2022",
    date_begin: { year: 2022, month: 7, day: 29 },
    date_end: { year: 2022, month: 8, day: 12 },
    where: "Espaço das Artes - Casa Cultura Setúbal",
    horario: "3ªf a Domingo - 16:00 às 19:00",
    Contact: "",
    image: ExposicaoImg4,
    link: "",
    scrollType: "",
  },
  {
    id: 5,
    title: "Cores de mim",
    subtitle: "Judite Pitta",
    text: "Exposição de artes plástivas com obras de Judite Pitta, sob o tema 'Cores de mim' com recurso a técnica de pintura em seda natural",
    highlight: "",
    when: "13 a 25 Agosto 2022",
    date_begin: { year: 2022, month: 8, day: 11 },
    date_end: { year: 2022, month: 8, day: 25 },
    where: "Espaço das Artes - Casa Cultura Setúbal",
    horario: "3ªf a Domingo - 16:00 às 19:00",
    Contact: "",
    image: ExposicaoImg5,
    link: "",
    scrollType: "",
  },
  {
    id: 6,
    title: "Entre Rostos",
    subtitle: "Fotografias de Luisa Monteiro",
    text: "Projeto Recriar-se (Cáritas)",
    highlight: "",
    when: "27 de Agosto a 08 Setembro 2022",
    date_begin: { year: 2022, month: 8, day: 27 },
    date_end: { year: 2022, month: 9, day: 8 },
    where: "Espaço das Artes - Casa Cultura Setúbal",
    horario: "3ªf a Domingo - 16:00 às 19:00",
    Contact: "",
    image: ExposicaoImg6,
    link: "",
    scrollType: "",
  },
  {
    id: 7,
    title: "Com Alma",
    subtitle: "Atelier de pintura GADIC /Câmara Municiapl de Elvas",
    text: "Exposição Coletiva de Artes Plásticas do atelier de Elvas sob orientação da Dra. Maria José Nunes Brito",
    highlight: "",
    when: "18 a 30 Junho 2022",
    date_begin: { year: 2022, month: 6, day: 18 },
    date_end: { year: 2022, month: 6, day: 30 },
    horario: "3ªf a Domingo - 16:00 às 19:00",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg7,
    link: "",
    scrollType: "",
  },
  {
    id: 8,
    title: "Arte Setúbal 2022",
    subtitle: "Exposição de Pintura",
    text: "Exposição coletiva de Artes Plásticas 'Arte Setúbal 2022', patente na biblioteca da ESCE/ESS, numa parceria entre a Associação de Artistas Plásticos de Setúbal (ARTISET) e o Instituto Politécnico de Setúbal (IPS). ",
    highlight: "",
    when: "5 Maio a 24 Junho 2022",
    date_begin: { year: 2022, month: 5, day: 5 },
    date_end: { year: 2022, month: 6, day: 24 },
    horario: "Segunda a Sexta - 10H00 às 17H00",
    where: "Biblioteca ESCE/ESS - Instituto Politécnico de Setúbal",
    Contact: "",
    image: ExposicaoImg8,
    link: "",
    scrollType: "",
  },
  {
    id: 9,
    title: "Exposição Coletiva da AAAGP",
    subtitle: "Artes Plásticas",
    text: "Exposição  coletica da de Artes Plástivas da Associação da Amizade e das Artes Galego Portuguesa sob o tema do Centenário de Saramago",
    highlight: "",
    when: "4 a 16 Junho 2022",
    date_begin: { year: 2022, month: 6, day: 4 },
    date_end: { year: 2022, month: 6, day: 16 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg9,
    link: "",
    scrollType: "",
  },
  {
    id: 10,
    title:
      "Exposição Artes Plásticas Alunos Finalistas\nCurso Artes Visuais\nEscola Secundária de Bocage",
    subtitle: "Artes Plásticas",
    text: "Exposição dos melhores trabalhos dos alunos finalistas do Cursos de Artes Visuais da Escola Secundária do Bocage. Uma seleção do Professor Rui Branco. Visitar esta exposição é ter o previlégio de conhecer uma amostra do talento jovem da nossa cidadde. A não perder.",
    highlight: "",
    when: "21 Maio a 2 Junho 2022",
    date_begin: { year: 2022, month: 5, day: 21 },
    date_end: { year: 2022, month: 6, day: 2 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg10,
    link: "",
    scrollType: "",
  },
  {
    id: 11,
    title: "Reflexões sobre Setúbal como Cidade Criativa",
    subtitle: "Palestra",
    text: "Palestra pelo Prof. Dr. Fernando Casqueira sob o tema de Reflexões sobre a Cidade de Setúbal como Cidade Criativa. Evento integrante do programa de comemorações do 31º aniversário da Artiset.",
    highlight: "",
    when: "22 Maio 2022",
    date_begin: { year: 2022, month: 5, day: 21 },
    date_end: { year: 2022, month: 5, day: 23 },
    horario: "16:00",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg11,
    link: "",
    scrollType: "",
  },
  {
    id: 12,
    title: "Magia d'Alma",
    subtitle: "Pintura de Carmo Candeias",
    text: "Exposição de pinturas de autoria de Carmi Candeias sob o tema Magia d'Alma.",
    highlight: "",
    when: "7 a 19 Maio 2022",
    date_begin: { year: 2022, month: 5, day: 7 },
    date_end: { year: 2022, month: 5, day: 19 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg12,
    link: "",
    scrollType: "",
  },
  {
    id: 13,
    title: "Arte e Terapia",
    subtitle: "Palestra com Margarida Macedo",
    text: "Palesta com Margarida Macedo sobre Arte e Terapia. Evento integrante do programa de comemorações do 31º aniversário da Artiset. ",
    highlight: "",
    when: "8 Maio 2022",
    date_begin: { year: 2022, month: 5, day: 7 },
    date_end: { year: 2022, month: 5, day: 8 },
    horario: "18H00",
    where: "Sala José Afonso - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg13,
    link: "",
    scrollType: "",
  },
  {
    id: 14,
    title: "Retalhos da vida de um fotogrado",
    subtitle: "Fotografias de Aníbal Madeira",
    text: "Exposição do Associado Aníbal Madeira.",
    highlight: "",
    when: "23 Abril a 5 de Maio 2022",
    date_begin: { year: 2022, month: 4, day: 23 },
    date_end: { year: 2022, month: 5, day: 5 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg14,
    link: "",
    scrollType: "",
  },
  {
    id: 15,
    title: "Universo da Mourisca",
    subtitle: "Exposição de Fotografia, Pintura e Escultura",
    text: "Exposição que terá lugar no moinho das Marés da Mourica, composta por obras de um grupo de artistas da Aritset, que constituem diversas visões do Universo da Mourisca.\nVenha descobrir a nossa exposição e disfrutar da beleza única do local. ",
    highlight: "",
    when: "5 Fev a 27 Março 2022",
    date_begin: { year: 2022, month: 2, day: 5 },
    date_end: { year: 2022, month: 3, day: 27 },
    horario: "Terça a Domingo - 10H00 às 17H00",
    where: "Moinho de Maré da Mourisca - Setúbal",
    Contact: "",
    image: ExposicaoImg15,
    link: "",
    scrollType: "",
  },
  {
    id: 16,
    title: "Coisas da minha terra",
    subtitle: "Pintura",
    text: "Exposição de pintura de Luz Angela Corso Sarmiente aob o tema Coisas da minha terra.",
    highlight: "",
    when: "Fev e Mar 2022",
    date_begin: { year: 2022, month: 2, day: 1 },
    date_end: { year: 2022, month: 3, day: 31 },
    horario: "",
    where: "Clinica dentaria Dr. Vanda Gandum \n Setúbal",
    Contact: "",
    image: ExposicaoImg16,
    link: "",
    scrollType: "",
  },
  {
    id: 17,
    title: "Essência",
    subtitle: "Exposição de Artes Plásticas",
    text: "A nossa exposição de Outono será composta por obras da nossa associada Margarida Rodrigues sob o tema Essência. \n Será mais um evento no contexto da parceria com a B&B Hotels.",
    highlight: "",
    when: "17 Outubro a 19 Dezembro 2021",
    date_begin: { year: 2021, month: 10, day: 17 },
    date_end: { year: 2021, month: 12, day: 19 },
    where: "B&B Hotel \nRua Irene Lisboa1 \n2900-028 Setúbal",
    Contact: "",
    image: ExposicaoImg17,
    link: "",
    scrollType: "",
  },
  {
    id: 18,
    title: "Arte Setúbal 2022",
    subtitle: "Exposição de Artes Plásticas",
    text: "Exposição coletiva de Artes Plásticas dos associados da Artiset sob o tema 'Arte Setúbal 2022'.",
    highlight: "",
    when: "10 a 22 Setembro 2022",
    date_begin: { year: 2022, month: 9, day: 9 },
    date_end: { year: 2022, month: 9, day: 23 },
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg18,
    link: "",
    scrollType: "",
  },
  {
    id: 19,
    title: 'Exposição Coletiva da Associação "Sopa de Artistas"',
    subtitle: "Exposição de Artes Plásticas",
    text: 'O Alentejo, mais precisamente o Concelho de Odemira, recebeu e aloja um grande grupo de artistas nacionais e estrangeieros que mais ou menos dispersos desenvolvem o seu trabalho. Lutam com algumas dificuldades para divulgar e aceder ao público em geral e ao mercado da arte em particular. A Associação denominada "Sopa dos Artistas" nasceu do encontro de alguns artistas que se congregam, com os seus pares, a fim de conferir visibilidade Às atividades artísticas que dsenvolvem isoladamente. O encontro nos seus espaços individuais conduziu às reuniões em torno da mesa onde se delinearam estratégias, definiram estatutos e se condimentaram as sopas de que tomaram o nome. Temos por objetivo dinamizar as artes plásticas organizando exposições, Workshops, colóquios, ateliers, feiraws, tertúlias e espetáculos proporcionando À população em geral e aos estudantes, em particular, atividades astístico-culturais das quais se encontra esta região tão carenciada.',
    highlight: "",
    when: "24 Setembro a 8 Outubro 2022",
    date_begin: { year: 2022, month: 9, day: 23 },
    date_end: { year: 2022, month: 10, day: 7 },
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg19,
    link: "",
    scrollType: "",
  },
  {
    id: 20,
    title: "Sem lágrimas",
    subtitle: "Exposição de Artes Plásticas",
    text: "Esposição do Atelier de Maria José Nunes de Brito",
    highlight: "",
    when: "8 a 20 Outubro 2022",
    date_begin: { year: 2022, month: 10, day: 7 },
    date_end: { year: 2022, month: 10, day: 21 },
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg20,
    link: "",
    scrollType: "",
  },
  {
    id: 21,
    title: "Gentes e estados de espírito",
    subtitle: "",
    text: "Exposição de Artes Plásticas de João Oliveira Pinto",
    highlight: "",
    when: "22 Outubro a 3 Novembro 2022",
    date_begin: { year: 2022, month: 10, day: 21 },
    date_end: { year: 2022, month: 11, day: 4 },
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg21,
    link: "",
    scrollType: "",
  },
  {
    id: 22,
    title: "Narrativa Abstrata",
    subtitle: "Exposição Pintura",
    text: "José Luís Aguiar tem o prazer de o/a convidar para a inauguração da exposição de pitnura, que terá lugar no Campus da Justiça (EXPO), no dia 4 de outubro das 17h30 Às 19h00. A exposição estará patente até 28 de  outubro.",
    highlight: "",
    when: "4 a 28 de Outubro 2022",
    date_begin: { year: 2022, month: 10, day: 3 },
    date_end: { year: 2022, month: 10, day: 29 },
    horario: "Segunda a Sexta-feira - 19H00 às 19H00",
    where:
      "Campus da Justiça (EXPO) - Rua D.João II Edifício H Piso 0 - 1990-097 Lisboa",
    Contact: "",
    image: ExposicaoImg22,
    link: "",
    scrollType: "",
  },
  {
    id: 23,
    title: "Reencontros",
    subtitle: "Exposição Artes Plásticas",
    text: "Exposição de artes plásticas dos ateliers 'Viv'arte' e 'Pequenos Jovens Artistas'",
    highlight: "",
    when: "5 a 17 de Novembro 2022",
    date_begin: { year: 2022, month: 11, day: 5 },
    date_end: { year: 2022, month: 11, day: 17 },
    horario: "Segunda a Sexta-feira - 19H00 às 19H00",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg23,
    link: "",
    scrollType: "",
  },
  {
    id: 24,
    title: "Sinais de Fogo",
    subtitle: "Exposição fotografia",
    text: "Árvores e arbustos queimados, cada espécie mostra diferentes cores após a passagem do fogo, aroreira e carrasco ruborizam, outros amarelecem, alguns cedros tentam resistir inglroiamente e manter alguma folhagem, pinheiros, eucaliptos e zambujeiros porica resistência oferecem, ferve-lhes a seiva até às entranhas, deixando buracos na terra onde outrora existiam raízes.",
    highlight: "",
    when: "19 Novembro a 1 de Dezembro 2022",
    date_begin: { year: 2022, month: 11, day: 19 },
    date_end: { year: 2022, month: 12, day: 1 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg24,
    link: "",
    scrollType: "",
  },
  {
    id: 25,
    title: "Retratos",
    subtitle: "Exposição Artes Plásticas",
    text: "Exposição de artes plásticas de João Barros, em colaboração com o Centrode EStudos Culturais e de Ação Social 'Raio de Luz'",
    highlight: "",
    when: "3 Dezembro 2022 a 5 de Janeiro 2023",
    date_begin: { year: 2022, month: 12, day: 3 },
    date_end: { year: 2023, month: 1, day: 5 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg25,
    link: "",
    scrollType: "",
  },
  {
    id: 26,
    title: "Gordillo - 60 Anos de Exposições",
    subtitle: "",
    text: "Exposição comemorativa dos 60 anos de exposições de Gordillo",
    highlight: "",
    when: "1 a 31 Dezembro 2022",
    date_begin: { year: 2022, month: 12, day: 1 },
    date_end: { year: 2022, month: 12, day: 31 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg26,
    link: "",
    scrollType: "",
  },
  {
    id: 27,
    title: "Mundos e Ideias",
    subtitle: "",
    text: "Exposição Artes Plásticas de José Girão",
    highlight: "",
    when: "7 a 19 Janeiro 2023",
    date_begin: { year: 2023, month: 1, day: 6 },
    date_end: { year: 2023, month: 1, day: 20 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg27,
    link: "",
    scrollType: "",
  },
  {
    id: 28,
    title: "Olha para Mim",
    subtitle: "",
    text: "Exposição de trabalhos de António Galrinhos, que nos relva um pouco da sua fase mais recente como pintor, usando como suporte o pano-cru.",
    highlight: "",
    when: "21 Janeiro a 2 Fevereiro 2022",
    date_begin: { year: 2023, month: 1, day: 20 },
    date_end: { year: 2023, month: 2, day: 3 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg28,
    link: "",
    scrollType: "",
  },
  {
    id: 29,
    title: "Incursões no Abstrato",
    subtitle: "José Luís Aguilar",
    text: "Exposição de trabalhos de José Luís Aguilar, na Sociedade de Belas Artes sob o tema Incursões no Abstrato",
    highlight: "",
    when: "26 Janeiro a 25 Fevereiro 2023",
    date_begin: { year: 2023, month: 1, day: 25 },
    date_end: { year: 2023, month: 2, day: 26 },
    horario: "",
    where: "Sociedade Belas Artes - Galeria Pintor Fernando Azevedo",
    Contact: "",
    image: ExposicaoImg29,
    link: "",
    scrollType: "",
  },
  {
    id: 30,
    title: "SOBREVIVER",
    subtitle: "Exposição de Pintura",
    text: "Exposição de trabalhos de Isabel Alfarrobinha, Fernanda Calado, Claude Yersim, João Mendão, Manueçla Conceição",
    highlight: "",
    when: "4 a 16 Março 2023",
    date_begin: { year: 2023, month: 3, day: 1 },
    date_end: { year: 2023, month: 3, day: 17 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg30,
    link: "",
    scrollType: "",
  },
  {
    id: 31,
    title: "Monentos, uma retrospectiva",
    subtitle: "Artes Plásticas",
    text: "Exposição de Artes Plásticas de Júlia Almeida",
    highlight: "",
    when: "18 Fevereiro a 2 Março 2023",
    date_begin: { year: 2023, month: 2, day: 17 },
    date_end: { year: 2023, month: 3, day: 3 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg31,
    link: "",
    scrollType: "",
  },
  {
    id: 32,
    title: "A arte de ser visto",
    subtitle: "Artes Plásticas",
    text: "Exposição de Artes Plásticas de João Rosa",
    highlight: "",
    when: "4 a 16 Fevereiro 2023",
    date_begin: { year: 2023, month: 2, day: 3 },
    date_end: { year: 2023, month: 2, day: 17 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg32,
    link: "",
    scrollType: "",
  },
  {
    id: 33,
    title: "The colors of Portugal",
    subtitle: "Exposição de Artes Plásticas",
    text: "Desenho e Pintura de Geoffrey Levitt",
    highlight: "",
    when: "1 a 13 Abril 2023",
    date_begin: { year: 2023, month: 3, day: 30 },
    date_end: { year: 2023, month: 4, day: 14 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg33,
    link: "",
    scrollType: "",
  },
  {
    id: 34,
    title: "As cores da água",
    subtitle: "Exposição de Artes plásticas",
    text: "Artes plásticas de Conceição Oliveira",
    highlight: "",
    when: "15 a 27 Abril 2023",
    date_begin: { year: 2023, month: 4, day: 13 },
    date_end: { year: 2023, month: 4, day: 29 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg34,
    link: "",
    scrollType: "",
  },
  {
    id: 35,
    title: "United Photo Press",
    subtitle: "Exposição fotográfica Internacional",
    text: "A maior exposição internacional de fotografia em Setúbal. 50 artistas de 41 Países",
    highlight: "",
    when: "18 a 30 Março 2023",
    date_begin: { year: 2023, month: 3, day: 16 },
    date_end: { year: 2023, month: 3, day: 31 },
    horario: "",
    where: "Espaço das Artes - Casa Cultura Setúbal",
    Contact: "",
    image: ExposicaoImg35,
    link: "",
    scrollType: "",
  },
  {
    id: 36,
    title: "Exposição Coletiva Artiset - 'Água - Um bem essencial'",
    subtitle: "Exposição Pintura",
    text: "A maior exposição internacional de fotografia em Setúbal. 50 artistas de 41 Países",
    highlight: "",
    when: "8 a 31 Maio 2023",
    date_begin: { year: 2023, month: 5, day: 7 },
    date_end: { year: 2023, month: 5, day: 31 },
    horario: "",
    where: "IPS - Biblioteca da Escola Superior de Ciências Empresariais",
    Contact: "",
    image: ExposicaoImg36,
    link: "",
    scrollType: "",
  },
  {
    id: 37,
    title: "32º Aniversário da Artiset",
    subtitle: "Exposição Pintura",
    text: "Celebração do 32º Aniversário da Artiset",
    highlight: "",
    when: "20 Maio 2023",
    date_begin: { year: 2023, month: 5, day: 19 },
    date_end: { year: 2023, month: 5, day: 21 },
    horario: "",
    where: "Casa da Cultura",
    Contact: "",
    image: ExposicaoImg37,
    link: "",
    scrollType: "",
  },
  {
    id: 38,
    title: "Cidades Invisíveis",
    subtitle: "Exposição Pintura de Miguel Amaral",
    text: "Inauguração dia 27 Maio às 16:00",
    highlight: "",
    when: "27 Maio a 8 Junho 2023",
    date_begin: { year: 2023, month: 5, day: 26 },
    date_end: { year: 2023, month: 6, day: 9 },
    horario: "",
    where: "Casa da Cultura - Espaço das Artes",
    Contact: "",
    image: ExposicaoImg38,
    link: "",
    scrollType: "",
  },
  {
    id: 39,
    title: "1º Concurso de Escultura da Artiset",
    subtitle: "",
    text: "Inserido nas atividades do 'Há festa no Parque'",
    highlight: "",
    when: "2 e 3 Junho 2023",
    date_begin: { year: 2023, month: 6, day: 1 },
    date_end: { year: 2023, month: 6, day: 4 },
    horario: "",
    where: "Parque do Bonfim",
    Contact: "",
    image: ExposicaoImg39,
    link: "",
    scrollType: "",
  },
  {
    id: 40,
    title: "Fest'Asso",
    subtitle: "",
    text: "Espetáculos Musicais, gastronomia, atividades associativas, animação infantil, mostra de artesanato e produtos locais são os atrtivos do certame",
    highlight: "",
    when: "23 Junho a 8 Julho 2023",
    date_begin: { year: 2023, month: 6, day: 22 },
    date_end: { year: 2023, month: 7, day: 9 },
    horario: "",
    where: "Largo Jose Afonso",
    Contact: "",
    image: ExposicaoImg40,
    link: "",
    scrollType: "",
  },
  {
    id: 41,
    title: "Há Festa no Parque",
    subtitle: "Integrado nas Jornadas Ambientais de Setúbal 2023",
    text: "Dois dias de animação para assinalar o encerramento do ano letivo e o Dia Mundial da Criança, com um vasto leque de atividades gratuitas, brincadeiras e tempo saudável em família. Desporto, exposições, espetáculos de teatro e de música, literatura e oficinas lúdico-pedagógicas compõem o programa da 15ª edição do evento organizado pelo município de Setúbal.",
    highlight: "",
    when: "3 a 4 Junho 2023",
    date_begin: { year: 2023, month: 6, day: 2 },
    date_end: { year: 2023, month: 6, day: 5 },
    horario: "",
    where: "Jardim do Bonfim",
    Contact: "",
    image: ExposicaoImg41,
    link: "",
    scrollType: "",
  },
  {
    id: 42,
    title: "Atenção, Arte exposta. Perigo",
    subtitle: "Exposição",
    text: "Exposição de obras de Alunos de Artes Visuais da Escola Secundária du Bocage.",
    highlight: "",
    when: "13 a 25 Maio 2023",
    date_begin: { year: 2023, month: 5, day: 12 },
    date_end: { year: 2023, month: 5, day: 26 },
    horario: "",
    where: "Casa da Cultura - Espaço das Artes",
    Contact: "",
    image: ExposicaoImg42,
    link: "",
    scrollType: "",
  },
  {
    id: 43,
    title: "Dummy",
    subtitle: "Exposição",
    text: "Dummy",
    highlight: "",
    when: "13 a 25 Maio 2024",
    date_begin: { year: 2024, month: 5, day: 12 },
    date_end: { year: 2024, month: 12, day: 26 },
    horario: "",
    where: "Casa da Cultura - Espaço das Artes",
    Contact: "",
    image: EventosComingSoon,
    link: "",
    scrollType: "",
  },
  {
    id: 44,
    title: "Dummy",
    subtitle: "Exposição",
    text: "Dummy",
    highlight: "",
    when: "13 a 25 Maio 2024",
    date_begin: { year: 2024, month: 5, day: 12 },
    date_end: { year: 2024, month: 12, day: 26 },
    horario: "",
    where: "Casa da Cultura - Espaço das Artes",
    Contact: "",
    image: EventosComingSoon,
    link: "",
    scrollType: "",
  },
  {
    id: 45,
    title: "Dummy",
    subtitle: "Exposição",
    text: "Dummy",
    highlight: "",
    when: "13 a 25 Maio 2024",
    date_begin: { year: 2024, month: 5, day: 12 },
    date_end: { year: 2024, month: 12, day: 26 },
    horario: "",
    where: "Casa da Cultura - Espaço das Artes",
    Contact: "",
    image: EventosComingSoon,
    link: "",
    scrollType: "",
  },
  {
    id: 46,
    title: "Dummy",
    subtitle: "Exposição",
    text: "Dummy",
    highlight: "",
    when: "13 a 25 Maio 2023",
    date_begin: { year: 2024, month: 5, day: 12 },
    date_end: { year: 2024, month: 12, day: 26 },
    horario: "",
    where: "Casa da Cultura - Espaço das Artes",
    Contact: "",
    image: EventosComingSoon,
    link: "",
    scrollType: "",
  },
  {
    id: 47,
    title: "Dummy",
    subtitle: "Exposição",
    text: "Dummy",
    highlight: "",
    when: "13 a 25 Maio 2023",
    date_begin: { year: 2024, month: 5, day: 12 },
    date_end: { year: 2024, month: 12, day: 26 },
    horario: "",
    where: "Casa da Cultura - Espaço das Artes",
    Contact: "",
    image: EventosComingSoon,
    link: "",
    scrollType: "",
  },
  {
    id: 48,
    title: "Dummy",
    subtitle: "Exposição",
    text: "Dummy",
    highlight: "",
    when: "13 a 25 Maio 2023",
    date_begin: { year: 2024, month: 5, day: 12 },
    date_end: { year: 2024, month: 12, day: 26 },
    horario: "",
    where: "Casa da Cultura - Espaço das Artes",
    Contact: "",
    image: EventosComingSoon,
    link: "",
    scrollType: "",
  },
];
