import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import ReactGA from "react-ga";
import "./artiset.css";
import { Main_Menu_Options } from "./configs/MenuBarConfig.js";
import { Main_Menu_Options_Translate } from "./configs/MenuBarConfig.js";
import artisetIcon from "./configs/images/artiset.ico";
import HomeMain from "./HomeMain";
import QuemSomosMain from "./QuemSomosMain";
import AtividadesMain from "./AtividadesMain";

function App() {
  const [selected, setSelected] = useState("PT");
  const [selectedMenu, setSelectedMenu] = useState("MainAtividades");
  const TRACKING_ID = "G-6QKPLDJMFD"; // OUR_TRACKING_ID

  ReactGA.initialize(TRACKING_ID);

  function MenuOptions(props) {
    const menuOptions = Main_Menu_Options;
    const menuOptionsTranslate = Main_Menu_Options_Translate;

    return (
      <div className="MainMenuBarItem">
        <ReactFlagsSelect
          className="flagSelector"
          countries={["PT", "GB", "FR", "ES"]}
          selected={selected}
          onSelect={(code) => setSelected(code)}
          placeholder="Selecione a Linguagem"
          showSelectedLabel={false}
          showOptionLabel={false}
          selectButtonClassName="menu-flags-button"
        />

        {menuOptions.map((menuOptions) => (
          <div>
            <button
              id={"MenuItem" + menuOptions.id}
              className={
                selectedMenu === menuOptions.id
                  ? "MainMenuBarItemSelected"
                  : "MainMenuBarItem"
              }
              onClick={() => {
                setSelectedMenu(menuOptions.id);
              }}
            >
              {menuOptionsTranslate[menuOptions.id][props.language]}
            </button>
          </div>
        ))}
        <div
          className="MainMenuBarIconFrame"
          onClick={() => {
            setSelectedMenu("MainHome");
          }}
        >
          <img className="MainMenuBarIcon" src={artisetIcon} alt="" />
          artiset
        </div>
      </div>
    );
  }

  function MenuBar(props) {
    return (
      <div className="MainMenuBar">
        <div className="MainMenuBarItems">
          <MenuOptions language={props.language} />
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <MenuBar language={selected} />
      <HomeMain language={selected} showMenu={selectedMenu} />
      <QuemSomosMain language={selected} showMenu={selectedMenu} />
      <AtividadesMain language={selected} showMenu={selectedMenu} />
    </div>
  );
}

export default App;
