import React from "react";
import "./artiset.css";

class HomeDestaqueSlideContent extends React.Component {
  render(props) {
    return (
      <div className="DestaqueContent">
        <img
          className="DestaqueImage"
          src={this.props.HomeDestaqueItem.image}
          alt=""
        />
      </div>
    );
  }
}

export default HomeDestaqueSlideContent;
