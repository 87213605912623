import React from "react";
import "./artiset.css";
import {
  Atividades_Eventos_List_Translate,
  Atividades_Eventos_List,
} from "./configs/AtividadesEventosConfig.js";

function ActEvenFotos(props) {
  const photosList = props.photosList;
  return (
    <div id={props.photoId} className="ActEvenFotos" hidden>
      {photosList.map((photosList, i) => (
        <div className="ActEvenFoto">
          <img
            className="AtividadesEventoItemImage"
            src={photosList.photo}
            alt=""
          />
        </div>
      ))}
    </div>
  );
}

function AtividadesEventosList(props) {
  const EventosList = props.EventosList;

  return (
    <div className="AtividadesEventosList">
      {EventosList.map((EventosList, i) => (
        <div className="AtividadesEventoItemList">
          <div className="AtividadesEventoItemFrame">
            <div className="AtividadesEventoItemUpperContent">
              <div className="AtividadesEventoItemImageFrame">
                <img
                  className="AtividadesEventoItemImage"
                  src={EventosList.image}
                  alt=""
                />
              </div>
              <div className="AtividadesEventoItem">
                {
                  Atividades_Eventos_List_Translate[EventosList.id][
                    props.language
                  ]["title"]
                }
                <br></br>
                <br></br>
                {
                  Atividades_Eventos_List_Translate[EventosList.id][
                    props.language
                  ]["text"]
                }
                <br></br>
                <br></br>
                {EventosList.date}
              </div>
            </div>
            <button
              id="btnfotos1"
              className="btnActEventosFotos"
              onClick={() => {
                if (
                  document.getElementById(EventosList.photolabel).hidden ===
                  true
                ) {
                  document.getElementById(
                    EventosList.photolabel
                  ).hidden = false;
                } else {
                  document.getElementById(EventosList.photolabel).hidden = true;
                }
              }}
            >
              Ver Fotos
            </button>

            <ActEvenFotos
              photoId={EventosList.photolabel}
              photosList={EventosList.photos}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

class AtividadesEventos extends React.Component {
  render(props) {
    return (
      <div id="AtividadesEventos" className="AtividadesEventosFrame" /*hidden*/>
        <div className="AtividadesEventosTitle">
          {
            this.props.menu_options_translate[this.props.menu_name][
              this.props.language
            ]
          }
        </div>

        <AtividadesEventosList
          language={this.props.language}
          EventosList={Atividades_Eventos_List}
        />
      </div>
    );
  }
}

export default AtividadesEventos;
