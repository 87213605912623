import React from "react";
import "./artiset.css";
import QuemSomos from "./QuemSomos";
import QuemSomosDirecao from "./QuemSomosDirecao";
import QuemSomosContatos from "./QuemSomosContatos";
import Apoios from "./ApoiosMain.js";

class MainQuemSomos extends React.Component {
  render(props) {
    return (
      <div
        id="MainQuemSomos"
        className="ContentOuterFrameColumn"
        style={
          this.props.showMenu === "MainQuemSomos"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <div className="ContentFixHeaderMenu"></div>
        <div className="ContentOuterFrameRow">
          <div className="ContentSideMarginLeft"></div>

          <div className="ContentMidle">
            <QuemSomos language={this.props.language} />
            <QuemSomosDirecao language={this.props.language} />
            <QuemSomosContatos language={this.props.language} />
            <Apoios language={this.props.language} />
          </div>

          <div className="ContentSideMarginRight"></div>
        </div>
      </div>
    );
  }
}

export default MainQuemSomos;
