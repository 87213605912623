import React from "react";
import "./artiset.css";
import { Apoios_List } from "./configs/ApoiosConfig.js";

function ApoiosList() {
  const ApoiosList = Apoios_List;

  return (
    <div className="ApoiosListItemsFrame">
      {ApoiosList.map((ApoiosList, i) => (
        <div className="ApoioImage">
          <a href={ApoiosList.link} target="_blank" rel="noopener noreferrer">
            <img className="ApoioImage" src={ApoiosList.image} alt="" />
          </a>
        </div>
      ))}
    </div>
  );
}

export default ApoiosList;
