import ProtocolosImg1 from "./images/protocolos/ProtocolosImg1.jpg";
import ProtocolosImg2 from "./images/protocolos/ProtocolosImg2.jpg";
import ProtocolosImg3 from "./images/protocolos/ProtocolosImg3.jpg";

export const Atividades_Protocolos_Translate = {
  Protocolo1: {
    ProtCardTitle: {
      PT: "AAAGP - Associação de Amizade das Artes Galego Portuguesa",
      GB: "AAAGP - Galician Portuguese Arts Friendship Association",
      FR: "AAAGP - Association d'amitié galicienne des arts portugais",
      ES: "AAAGP - Asociación de Amistad de las Artes Gallego-Portuguesas",
    },
    ProtCardText1: {
      PT: "Associação de Artistas que acolhe no seu seio pintores, fotografos, escultores, poetas, escritores e outros amantes das artes.",
      GB: "Association of Artists that welcomes painters, photographers, sculptors, poets, writers and other lovers of the arts.",
      FR: "Association d'Artistes qui accueille peintres, photographes, sculpteurs, poètes, écrivains et autres amoureux des arts.",
      ES: "Asociación de Artistas que acoge a pintores, fotógrafos, escultores, poetas, escritores y demás amantes de las artes.",
    },
    ProtCardText2: {
      PT: "Esta associação foi fundada em 2007 por um grupo de amigos galegos e portugueses, e tem sede na Figueira da Foz.",
      GB: "This association was founded in 2007 by a group of Galician and Portuguese friends, and is based in Figueira da Foz.",
      FR: "Cette association a été fondée en 2007 par un groupe d'amis galiciens et portugais et est basée à Figueira da Foz.",
      ES: "Esta asociación fue fundada en 2007 por un grupo de amigos gallegos y portugueses, y tiene su sede en Figueira da Foz.",
    },
    ProtCardImage: {
      PT: ProtocolosImg1,
      GB: ProtocolosImg1,
      FR: ProtocolosImg1,
      ES: ProtocolosImg1,
    },
    /*ProtCardContato: {
      PT: "Contato: 963034025",
      GB: "Contact: 963034025",
      FR: "Contact: 963034025",
      ES: "Contacto: 963034025",
    },*/
  },
  Protocolo2: {
    ProtCardTitle: {
      PT: "Paço das Artes",
      GB: "Palace of the Arts",
      FR: "Palais des Arts",
      ES: "Palacio de las Artes",
    },
    ProtCardText1: {
      PT: "Associação de Artistas Plásticos de Paço de Arços",
      GB: "Association of Plastic Artists of Paço de Arços",
      FR: "Association des artistes plasticiens de Paço de Arços",
      ES: "Asociación de Artistas Plásticos de Paço de Arços",
    },
    ProtCardText2: {
      PT: "A Paço das Artes é um associação similar à Artiset, com a qual estebelecemos uma pareceria de colaboração, dadas as afinidades entre as duas associações.",
      GB: "Paço das Artes is an association similar to Artiset, with which we established a collaborative partnership, given the affinities between the two associations.",
      FR: "Paço das Artes est une association similaire à Artiset, avec laquelle nous avons établi un partenariat de collaboration, compte tenu des affinités entre les deux associations.",
      ES: "Paço das Artes es una asociación similar a Artiset, con la que establecimos una sociedad de colaboración, dadas las afinidades entre las dos asociaciones.",
    },
    ProtCardImage: {
      PT: ProtocolosImg2,
      GB: ProtocolosImg2,
      FR: ProtocolosImg2,
      ES: ProtocolosImg2,
    },
    /*ProtCardContato: {
      PT: "Contato: 966048038",
      GB: "Contact: 966048038",
      FR: "Contact: 966048038",
      ES: "Contacto: 966048038",
    },*/
  },
  Protocolo3: {
    ProtCardTitle: {
      PT: "Câmara Municipal de Setúbal",
      GB: "Setubal City Council",
      FR: "Conseil municipal de Setúbal",
      ES: "Ayuntamiento de Setúbal",
    },
    ProtCardText1: {
      PT: "A Camâra Municipal de Setúbal tem um protocolo de colaboração com a Artiset, e tem sido um grande apoiante da mesma.",
      GB: "The Municipality of Setúbal has a collaboration protocol with Artiset, and has been a great supporter of it.",
      FR: "La municipalité de Setúbal a un protocole de collaboration avec Artiset et en a été un grand partisan.",
      ES: "El Municipio de Setúbal tiene un protocolo de colaboración con Artiset, y ha sido un gran partidario del mismo.",
    },
    ProtCardText2: {
      PT: "Além de colaborar em vários eventos promovidos pela Artiset, cedeu em 2012 o espaço da artes, na Casa da Cultura em Setúbal, onde é atualmente a sede da Artiset.",
      GB: "In addition to collaborating in various events promoted by Artiset, in 2012 he provided the arts space at the Casa da Cultura in Setúbal, where Artiset is currently headquartered.",
      FR: "En plus de collaborer à divers événements promus par Artiset, en 2012, il a fourni l'espace artistique de la Casa da Cultura à Setúbal, où Artiset a actuellement son siège.",
      ES: "Además de colaborar en varios eventos promovidos por Artiset, en 2012 brindó el espacio artístico de la Casa da Cultura de Setúbal, donde Artiset tiene actualmente su sede.",
    },
    ProtCardImage: {
      PT: ProtocolosImg3,
      GB: ProtocolosImg3,
      FR: ProtocolosImg3,
      ES: ProtocolosImg3,
    },
    /*ProtCardContato: {
      PT: "Contato: 966048038",
      GB: "Contact: 966048038",
      FR: "Contact: 966048038",
      ES: "Contacto: 966048038",
    },*/
  },
};
