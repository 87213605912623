import React from "react";
import "./artiset.css";
import HomeDestaqueSlideShow from "./HomeDestaqueSlideShow.js";

class HomeDestaque extends React.Component {
  render(props) {
    return (
      <div id="Destaque" className="DestaqueFrame">
        <HomeDestaqueSlideShow language={this.props.language} />
      </div>
    );
  }
}

export default HomeDestaque;
