import AtividadeEventosImage1 from "./images/Eventos/EVT_31 aniversario artiset 22052022.png";
import AtividadeEventosImage2 from "./images/Eventos/EVT_Churrascada Anos80 28Maio2022.jpg";
import AtividadeEventosImage3 from "./images/Eventos/EVT_DesenhoArLivreCastroChibanes21Maio2022.jpg";
import AtividadeEventosImage4 from "./images/Eventos/EVT_Agua_IPS_2023.JPG";

import ActEven1Foto1 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 1.jpg";
import ActEven1Foto2 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 2.jpg";
import ActEven1Foto3 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 3.jpg";
import ActEven1Foto4 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 4.jpg";
import ActEven1Foto5 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 5.jpg";
import ActEven1Foto6 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 6.jpg";
import ActEven1Foto7 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 7.jpg";
import ActEven1Foto8 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 8.jpg";
import ActEven1Foto9 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 9.jpg";
import ActEven1Foto10 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 10.jpg";
import ActEven1Foto11 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 11.jpg";
import ActEven1Foto12 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 12.jpg";
import ActEven1Foto13 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 13.jpg";
import ActEven1Foto14 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 14.jpg";
import ActEven1Foto15 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 15.jpg";
import ActEven1Foto16 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 16.jpg";
import ActEven1Foto17 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 17.jpg";
import ActEven1Foto18 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 18.jpg";
import ActEven1Foto19 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 19.jpg";
import ActEven1Foto20 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 20.jpg";
import ActEven1Foto21 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 21.jpg";
import ActEven1Foto22 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 22.jpg";
import ActEven1Foto23 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 23.jpg";
import ActEven1Foto24 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 24.jpg";
import ActEven1Foto25 from "./images/Eventos/Evento1/EVT_Aniversario 31 artiset 25.jpg";
import ActEven2Foto1 from "./images/Eventos/Evento2/EVT_Churrasco Volta Pedra 1.jpeg";
import ActEven2Foto2 from "./images/Eventos/Evento2/EVT_Churrasco Volta Pedra 2.jpeg";
import ActEven2Foto3 from "./images/Eventos/Evento2/EVT_Churrasco Volta Pedra 3.jpeg";
import ActEven2Foto4 from "./images/Eventos/Evento2/EVT_Churrasco Volta Pedra 4.jpeg";
import ActEven2Foto5 from "./images/Eventos/Evento2/EVT_Churrasco Volta Pedra 5.jpeg";
import ActEven2Foto6 from "./images/Eventos/Evento2/EVT_Churrasco Volta Pedra 6.jpeg";
import ActEven2Foto7 from "./images/Eventos/Evento2/EVT_Churrasco Volta Pedra 7.jpeg";
import ActEven2Foto8 from "./images/Eventos/Evento2/EVT_Churrasco Volta Pedra 8.jpeg";
import ActEven2Foto9 from "./images/Eventos/Evento2/EVT_Churrasco Volta Pedra 9.jpeg";
import ActEven2Foto10 from "./images/Eventos/Evento2/EVT_Churrasco Volta Pedra 10.jpeg";

import ActEven3Foto1 from "./images/Eventos/Evento3/EVT_Castro Chibanes 1 Placard.jpg";
import ActEven3Foto2 from "./images/Eventos/Evento3/EVT_Castro Chibanes 2 Local.jpg";
import ActEven3Foto3 from "./images/Eventos/Evento3/EVT_Castro Chibanes 3 Aguarela.jpg";
import ActEven3Foto4 from "./images/Eventos/Evento3/EVT_Castro Chibanes 4 Tour.jpg";
import ActEven3Foto5 from "./images/Eventos/Evento3/EVT_Castro Chibanes 5 Grupo.jpg";
import ActEven3Foto6 from "./images/Eventos/Evento3/EVT_Castro Chibanes 6 Rocha.jpg";
import ActEven3Foto7 from "./images/Eventos/Evento3/EVT_Castro Chibanes 7 Equipa.jpg";
import ActEven3Foto8 from "./images/Eventos/Evento3/EVT_Castro Chibanes 8 Detalhe.jpg";
import ActEven3Foto9 from "./images/Eventos/Evento3/EVT_Castro Chibanes 9 Fim.jpg";

import ActEven4Foto1 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_1.JPG";
import ActEven4Foto2 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_2.JPG";
import ActEven4Foto3 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_3.JPG";
import ActEven4Foto4 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_4.JPG";
import ActEven4Foto5 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_5.JPG";
import ActEven4Foto6 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_6.JPG";
import ActEven4Foto7 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_7.JPG";
import ActEven4Foto8 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_8.JPG";
import ActEven4Foto9 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_9.JPG";
import ActEven4Foto10 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_10.JPG";
import ActEven4Foto11 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_11.JPG";
import ActEven4Foto12 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_12.JPG";
import ActEven4Foto13 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_13.JPG";
import ActEven4Foto14 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_14.JPG";
import ActEven4Foto15 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_15.JPG";
import ActEven4Foto16 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_16.JPG";
import ActEven4Foto17 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_17.JPG";
import ActEven4Foto18 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_18.JPG";
import ActEven4Foto19 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_19.JPG";
import ActEven4Foto20 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_20.JPG";
import ActEven4Foto21 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_21.JPG";
import ActEven4Foto22 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_22.JPG";
import ActEven4Foto23 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_23.JPG";
import ActEven4Foto24 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_24.JPG";
import ActEven4Foto25 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_25.JPG";
import ActEven4Foto26 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_26.JPG";
import ActEven4Foto27 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_27.JPG";
import ActEven4Foto28 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_28.JPG";
import ActEven4Foto29 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_29.JPG";
import ActEven4Foto30 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_30.JPG";
import ActEven4Foto31 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_31.JPG";
import ActEven4Foto32 from "./images/Eventos/Evento4/EVT_Agua_IPS_2023_32.JPG";

export const Atividades_Eventos_List_Translate = {
  AtividadeEvento1: {
    PT: {
      title: "31º Aníversário da Artiset",
      text: "Comemorações do 31º aniversário da Artiset.",
    },
    GB: {
      title: "Artiset's 31st Anniversary",
      text: "Celebrations of the 31st anniversary of Artistet.",
    },
    FR: {
      title: "31e anniversaire d'Artiset",
      text: "Célébrations du 31e anniversaire d'Artistet.",
    },
    ES: {
      title: "31º Aniversario de Artiset",
      text: "Celebrations of the 31st anniversary of Artistet.",
    },
  },
  AtividadeEvento2: {
    PT: {
      title: "Churrasco Anos 80 - Grupo Desportivo Volta da Pedra",
      text: "Jantar comemorativo do 31º Aniversário da Artiset. Churrasco seguido de momento musical Anos 80, no Grupo Desportivo da Volta da Pedra.",
    },
    GB: {
      title: "80's Barbecue - Grupo Desportivo Volta da Pedra",
      text: "Dinner commemorating the 31st Anniversary of Artiset. Barbecue followed by a musical moment in the 80s, at Grupo Desportivo da Volta da Pedra.",
    },
    FR: {
      title: "Barbecue des années 80 - Grupo Desportivo Volta da Pedra",
      text: "Dîner commémoratif du 31e anniversaire d'Artiset. Barbecue suivi d'un moment musical dans les années 80, au Grupo Desportivo da Volta da Pedra.",
    },
    ES: {
      title: "Barbacoa de los 80 - Grupo Desportivo Volta da Pedra",
      text: "Cena conmemorativa del 31 Aniversario de Artiset. Barbacoa seguida de un momento musical en los años 80, en el Grupo Desportivo da Volta da Pedra.",
    },
  },
  AtividadeEvento3: {
    PT: {
      title: "Desenho ao Ar Livre - Castro Chibanes",
      text: "Atividade de desenho ao ar livre seguido de visita guiada às ruínas de Castro Chibanes. Esta atividade foi promovida pelo Museu de Arqueologia e Etnografia de Setúbal, e faz parte das comemorações do Dia Internacional dos Museus. A artiset agradece o convite do MAEDS e foi um prazer ter podido participar nesta atividade tão enriquecedora.",
    },
    GB: {
      title: "Outdoor Drawing - Castro Chibanes",
      text: "Outdoor drawing activity followed by a guided tour of the Castro Chibanes ruins. This activity was promoted by the Museum of Archeology and Ethnography of Setúbal, and is part of the celebrations of International Museum Day. Artistet thanks MAEDS for the invitation and it was a pleasure to have been able to participate in this very enriching activity.",
    },
    FR: {
      title: "Dessin extérieur - Castro Chibanes",
      text: "Activité de dessin en plein air suivie d'une visite guidée des ruines de Castro Chibanes. Cette activité a été promue par le Musée d'archéologie et d'ethnographie de Setúbal et fait partie des célébrations de la Journée internationale des musées. Artiset remercie le MAEDS pour l'invitation et ce fut un plaisir d'avoir pu participer à cette activité très enrichissante.",
    },
    ES: {
      title: "Dibujo al aire libre - Castro Chibanes",
      text: "Actividad de dibujo al aire libre seguida de una visita guiada a las ruinas de Castro Chibanes. Esta actividad fue promovida por el Museo de Arqueología y Etnografía de Setúbal, y forma parte de las celebraciones del Día Internacional de los Museos. El artiset agradece a MAEDS por la invitación y fue un placer haber podido participar en esta actividad tan enriquecedora.",
    },
  },
  AtividadeEvento4: {
    PT: {
      title:
        "Inauguração da Exposição Coletiva da Artiset - 'A Água - Um bem essencial'",
      text: "Inaguração da Exposição Coletiva da Artiset que ocorreu no Instituto Politécnico de Setúbal, na biblioteca da Escola Superior de Ciências Empresariais.",
    },
    GB: {
      title:
        "Opening of the Artiset Collective Exhibition - 'Water - An essential good",
      text: "Opening of the Collective Exhibition of the Artist that took place at the Polytechnic Institute of Setúbal, in the library of the Escola Superior de Ciências Empresariais.",
    },
    FR: {
      title:
        "Inauguration de l'exposition collective Artiset - 'L'eau - Un bien essentiel",
      text: "Inauguration de l'exposition collective de l'artiste qui a eu lieu à l'Institut polytechnique de Setúbal, dans la bibliothèque de l'Escola Superior de Ciências Empresariais.",
    },
    ES: {
      title:
        "Inauguración de la Exposición Colectiva Artiset - 'Agua - Un bien imprescindible",
      text: "Inauguración de la Exposición Colectiva del Artista que tuvo lugar en el Instituto Politécnico de Setúbal, en la biblioteca de la Escola Superior de Ciências Empresariais.",
    },
  },
};

export const Atividades_Eventos_List = [
  {
    id: "AtividadeEvento1",
    image: AtividadeEventosImage1,
    date: "22.05.2022",
    photolabel: "photo1",
    photos: [
      { photo: ActEven1Foto1 },
      { photo: ActEven1Foto2 },
      { photo: ActEven1Foto3 },
      { photo: ActEven1Foto4 },
      { photo: ActEven1Foto5 },
      { photo: ActEven1Foto6 },
      { photo: ActEven1Foto7 },
      { photo: ActEven1Foto8 },
      { photo: ActEven1Foto9 },
      { photo: ActEven1Foto10 },
      { photo: ActEven1Foto11 },
      { photo: ActEven1Foto12 },
      { photo: ActEven1Foto13 },
      { photo: ActEven1Foto14 },
      { photo: ActEven1Foto15 },
      { photo: ActEven1Foto16 },
      { photo: ActEven1Foto17 },
      { photo: ActEven1Foto18 },
      { photo: ActEven1Foto19 },
      { photo: ActEven1Foto20 },
      { photo: ActEven1Foto21 },
      { photo: ActEven1Foto22 },
      { photo: ActEven1Foto23 },
      { photo: ActEven1Foto24 },
      { photo: ActEven1Foto25 },
    ],
  },
  {
    id: "AtividadeEvento2",
    image: AtividadeEventosImage2,
    date: "28.05.2022",
    photolabel: "photo2",
    photos: [
      { photo: ActEven2Foto1 },
      { photo: ActEven2Foto2 },
      { photo: ActEven2Foto3 },
      { photo: ActEven2Foto4 },
      { photo: ActEven2Foto5 },
      { photo: ActEven2Foto6 },
      { photo: ActEven2Foto7 },
      { photo: ActEven2Foto8 },
      { photo: ActEven2Foto9 },
      { photo: ActEven2Foto10 },
    ],
  },
  {
    id: "AtividadeEvento3",
    image: AtividadeEventosImage3,
    date: "21.05.2022",
    photolabel: "photo3",
    photos: [
      { photo: ActEven3Foto1 },
      { photo: ActEven3Foto2 },
      { photo: ActEven3Foto3 },
      { photo: ActEven3Foto4 },
      { photo: ActEven3Foto5 },
      { photo: ActEven3Foto6 },
      { photo: ActEven3Foto7 },
      { photo: ActEven3Foto8 },
      { photo: ActEven3Foto9 },
    ],
  },
  {
    id: "AtividadeEvento4",
    image: AtividadeEventosImage4,
    date: "08.05.2023",
    photolabel: "photo4",
    photos: [
      { photo: ActEven4Foto1 },
      { photo: ActEven4Foto2 },
      { photo: ActEven4Foto3 },
      { photo: ActEven4Foto4 },
      { photo: ActEven4Foto5 },
      { photo: ActEven4Foto6 },
      { photo: ActEven4Foto7 },
      { photo: ActEven4Foto8 },
      { photo: ActEven4Foto9 },
      { photo: ActEven4Foto10 },
      { photo: ActEven4Foto11 },
      { photo: ActEven4Foto12 },
      { photo: ActEven4Foto13 },
      { photo: ActEven4Foto14 },
      { photo: ActEven4Foto15 },
      { photo: ActEven4Foto16 },
      { photo: ActEven4Foto17 },
      { photo: ActEven4Foto18 },
      { photo: ActEven4Foto19 },
      { photo: ActEven4Foto20 },
      { photo: ActEven4Foto21 },
      { photo: ActEven4Foto22 },
      { photo: ActEven4Foto23 },
      { photo: ActEven4Foto24 },
      { photo: ActEven4Foto25 },
      { photo: ActEven4Foto26 },
      { photo: ActEven4Foto27 },
      { photo: ActEven4Foto28 },
      { photo: ActEven4Foto29 },
      { photo: ActEven4Foto30 },
      { photo: ActEven4Foto31 },
      { photo: ActEven4Foto32 },
    ],
  },
];
