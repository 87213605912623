export const Main_Menu_Options_Translate = {
  MainApoios: {
    PT: "Parceiros",
    GB: "Partners",
    FR: "Partenaires",
    ES: "Soportes",
  },
  MainSocios: {
    PT: "Associados",
    GB: "Associates",
    FR: "Associés",
    ES: "Asociados",
  },
  MainAtividades: {
    PT: "Atividades",
    GB: "Activities",
    FR: "Activités",
    ES: "Actividades",
  },
  MainEventos: {
    PT: "Exposições",
    GB: "Exhibitions",
    FR: "Expositions",
    ES: "Exhibiciones",
  },
  MainQuemSomos: {
    PT: "Quem Somos",
    GB: "Who We Are",
    FR: "Qui nous sommes",
    ES: "Quienes somos",
  },
  MainDestaque: {
    PT: "Em Destaque",
    GB: "Highlights",
    FR: "Souligné",
    ES: "Destacado",
  },
  MainHome: {
    PT: "Home",
    GB: "Home",
    FR: "Home",
    ES: "Home",
  },
};

export const Main_Menu_Options = [
  /* {
    id: "MainApoios",
    title: "Parceiros",
    link: "/Apoios",
    scrollType: "start",
    scrollOffset: 130,
  },
  {
    id: "MainSocios",
    title: "Sócios",
    link: "/Socios",
    scrollType: "start",
    scrollOffset: 130,
  },*/
  {
    id: "MainAtividades",
    title: "Atividades",
    link: "/Atividades",
    scrollType: "start",
    scrollOffset: 130,
  },
  /* {
    id: "MainEventos",
    title: "Eventos",
    link: "/Eventos",
    scrollType: "start",
    scrollOffset: 130,
  },
*/
  {
    id: "MainQuemSomos",
    title: "Quem Somos",
    link: "/QuemSomos",
    scrollType: "start",
    scrollOffset: 130,
  },
  /* {
    id: "MainHome",
    title: "Home",
    link: "/HomeArtiset",
    scrollType: "start",
    scrollOffset: 0,
  },*/
];

export const Socios_Menu_Options_Translate = {
  SociosSejaSocio: {
    PT: "Seja Associado",
    GB: "Be an Associate",
    FR: "Devenir associé",
    ES: "Sea Asociado",
  },
  SociosGaleria: {
    PT: "Galeria",
    GB: "Associates Gallery",
    FR: "Galerie des Associés",
    ES: "Galería de Asociados",
  },
};

export const Socios_Menu_Options = [
  {
    id: "SociosSejaSocio",
    title: "Seja Associado",
    link: "/SociosSejaSocio",
    scrollType: "center",
    path: "SociosSejaSocio",
    btnId: "btnSociosSejaSocio",
  },
  {
    id: "SociosGaleria",
    title: "Galeria de Associados",
    link: "/SociosGaleria",
    scrollType: "center",
    path: "SociosGaleria",
    btnId: "btnSociosGaleria",
  },
];

export const QuemSomos_Menu_Options_Translate = {
  QuemSomos: {
    PT: "Artiset",
    GB: "Artiset",
    FR: "Artiset",
    ES: "Artiset",
  },
  QuemSomosDirecao: {
    PT: "Direção",
    GB: "Management",
    FR: "Gestion",
    ES: "Administración",
  },
  Estatutos: {
    PT: "Estatutos",
    GB: "Statutes",
    FR: "Statuts",
    ES: "Estatutos",
  },
  Contatos: {
    PT: "Contatos",
    GB: "Contacts",
    FR: "Contacts",
    ES: "Contactos",
  },
};

export const QuemSomos_Menu_Options = [
  {
    id: "QuemSomos",
    title: "Artiset",
    link: "/QuemSomos",
    scrollType: "start",
    path: "QuemSomos",
    btnId: "btnQuemSomosMain",
  },
  {
    id: "QuemSomosDirecao",
    title: "Direção",
    link: "/QuemSomosDirecao",
    scrollType: "start",
    path: "QuemSomosDirecao",
    btnId: "btnQuemSomosDirecao",
  },
  {
    id: "Contatos",
    title: "Contatos",
    link: "/Contatos",
    scrollType: "start",
    path: "QuemSomosContatos",
    btnId: "btnQuemSomosContatos",
  },
];

export const Atividades_Menu_Options_Translate = {
  Formacao: {
    PT: "Formação",
    GB: "Training",
    FR: "Formation",
    ES: "Formación",
  },
  Protocolos: {
    PT: "Protocolos",
    GB: "Protocols",
    FR: "Protocoles",
    ES: "Protocolos",
  },
  Eventos: {
    PT: "Eventos",
    GB: "Events",
    FR: "Événements",
    ES: "Eventos",
  },
};

export const Atividades_Menu_Options = [
  {
    id: "Formacao",
    title: "Formação",
    link: "/Formação",
    scrollType: "start",
    path: "AtividadesFormacao",
    btnId: "btnAtividadesFormacao",
  },
  {
    id: "Protocolos",
    title: "Protocolos",
    link: "/Protocolos",
    scrollType: "start",
    path: "AtividadesProtocolos",
    btnId: "btnAtividadesProtocolos",
  },
  {
    id: "Eventos",
    title: "Convívios",
    link: "/Eventos",
    scrollType: "start",
    path: "AtividadesEventos",
    btnId: "btnAtividadesEventos",
  },
];

export const Dummy_Menu_Options = [
  { id: "Dummy", title: "Dummy", link: "/Dummy", scrollType: "center" },
];
