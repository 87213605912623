export const Atividades_Formacao_Translate = {
  Formacao1: {
    FormCardTitle: {
      PT: "Aulas de Pintura para crianças - Pequenos Jovens Artistas",
      GB: "Painting Classes for Children - Little Young Artists",
      FR: "Cours de Peinture pour Enfants - Petits Jeunes Artistes",
      ES: "Clases de Pintura para Niños - Pequeños Artistas Jóvenes",
    },
    FormCardText1: {
      PT: "Orientação: Prof. Margarida Rodrigues",
      GB: "Orientation: Prof. Margarida Rodrigues",
      FR: "Orientation : Pr. Margarida Rodrigues",
      ES: "Orientación: Prof. Margarida Rodrigues",
    },
    FormCardText2: {
      PT: "Idades entre 6 e 14 anos",
      GB: "Ages between 6 and 14 years",
      FR: "Entre 6 et 14 ans",
      ES: "Edades entre 6 y 14 años",
    },
    FormCardHorario: {
      PT: "Domingos 10h-12h",
      GB: "Sundays 10h-12h",
      FR: "Dimanches 10h-12h",
      ES: "Domingos 10h-12h",
    },
    FormCardContato: {
      PT: "Marcações Prévias (vagas limitadas): 918 928 573",
      GB: "Appointments in advance (limited spaces): 918 928 573",
      FR: "Rendez-vous à l'avance (places limitées) : 918 928 573",
      ES: "Citas previas (plazas limitadas): 918 928 573",
    },
  },
  Formacao2: {
    FormCardTitle: {
      PT: "Aulas de Desenho e Pintura",
      GB: "Drawing and Painting Classes",
      FR: "Cours de dessin et de peinture",
      ES: "Clases de Dibujo y Pintura",
    },
    FormCardText1: {
      PT: "Orientação: Dª Maria José Brito",
      GB: "Orientation: Dª Maria José Brito",
      FR: "Orientation: Dª Maria José Brito",
      ES: "Orientación: Dª María José Brito",
    },
    FormCardText2: {
      PT: "Sem limite de idade",
      GB: "No age limit",
      FR: "Pas de limite d'âge",
      ES: "Sin límite de edad",
    },
    FormCardHorario: {
      PT: "Quartas-feiras - 10:00 às 12:00",
      GB: "Wednesdays - 10:00 to 12:00",
      FR: "Mercredi - 10:00 à 12:00",
      ES: "Miércoles - 10:00 a 12:00",
    },
    FormCardContato: {
      PT: "Marcações Prévias: 966 048 038",
      GB: "Appointments in advance: 966 048 038",
      FR: "Rendez-vous à l'avance: 966 048 038",
      ES: "Citas previas: 966 048 038",
    },
  },
  Formacao3: {
    FormCardTitle: {
      PT: "Aulas de Aguarela e Acrílico",
      GB: "Watercolor and Acrylic Classes",
      FR: "Cours d'aquarelle et d'acrylique",
      ES: "Clases de Acuarela y Acrílico",
    },
    FormCardText1: {
      PT: "Orientação: Isabel Alfarrobinha",
      GB: "Orientation: Isabel Alfarrobinha",
      FR: "Orientation: Isabel Alfarrobinha",
      ES: "Orientación: Isabel Alfarrobinha",
    },
    FormCardText2: {
      PT: "Sem limite de idade",
      GB: "No age limit",
      FR: "Pas de limite d'âge",
      ES: "Sin límite de edad",
    },
    FormCardHorario: {
      PT: "Quintas-feiras - 16:00 às 18:00",
      GB: "Thursdays - 16:00 to 18:00",
      FR: "Jeudi - 16h00 à 18h00",
      ES: "Jueves - 16:00 a 18:00",
    },
    FormCardContato: {
      PT: "Marcações Prévias: 934 055 290",
      GB: "Appointments in advance: 934 055 290",
      FR: "Rendez-vous à l'avance: 934 055 290",
      ES: "Citas previas: 934 055 290",
    },
  },
};
