import ApoiosImage1 from "./images/apoios/apoio1.jpg";
import ApoiosImage2 from "./images/apoios/apoio2.jpg";
import ApoiosImage3 from "./images/apoios/apoio3.jpg";
import ApoiosImage4 from "./images/apoios/apoio4.jpg";
import ApoiosImage5 from "./images/apoios/apoio5.jpg";
import ApoiosImage6 from "./images/apoios/apoio6.jpg";
import ApoiosImage7 from "./images/apoios/apoio7.jpg";
import ApoiosImage8 from "./images/apoios/apoio8.jpg";
import ApoiosImage9 from "./images/apoios/apoio9.jpg";
import ApoiosImage10 from "./images/apoios/apoio10.jpg";
import ApoiosImage11 from "./images/apoios/DecorFlor.jpg";

export const Apoios_List = [
  {
    id: " ",
    title: "Camâra Municipal Setúbal",
    text: "",
    Contact: "",
    image: ApoiosImage1,
    link: "http://www.mun-setubal.pt",
    scrollType: "",
  },
  {
    id: " ",
    title: "B&B Sado Hotel",
    text: "",
    Contact: "",
    image: ApoiosImage2,
    link: "https://www.hotel-bb.com/pt",
    scrollType: "",
  },
  {
    id: " ",
    title: "Dr. Vanda Gandum - Clínica Dentária",
    text: "",
    Contact: "",
    image: ApoiosImage3,
    link: "http://www.clinicavandagandum.com",
    scrollType: "",
  },
  {
    id: " ",
    title: "QuadriMóvel",
    text: "",
    Contact: "",
    image: ApoiosImage4,
    link: "http://pontodasartes.com",
    scrollType: "",
  },
  {
    id: " ",
    title: "Tintas com Arte",
    text: "",
    Contact: "",
    image: ApoiosImage5,
    link: "",
    scrollType: "",
  },
  {
    id: " ",
    title: "Ícone Molduras",
    text: "",
    Contact: "",
    image: ApoiosImage6,
    link: "http://icone-molduras.com",
    scrollType: "",
  },
  {
    id: " ",
    title: "Associação Coletividades Concelho Setúbal",
    text: "",
    Contact: "",
    image: ApoiosImage7,
    link: "https://www.facebook.com/associacaodascoletividadesconcelhosetubal/",
    scrollType: "",
  },
  {
    id: " ",
    title: "Associação Amizade e das Artes Galego Portuguesa",
    text: "",
    Contact: "",
    image: ApoiosImage8,
    link: "http://aaagp-portugal-pt.blogspot.com/",
    scrollType: "",
  },
  {
    id: " ",
    title: "United Photo Press",
    text: "",
    Contact: "",
    image: ApoiosImage9,
    link: "",
    scrollType: "",
  },
  {
    id: " ",
    title: "Paço de Artes",
    text: "",
    Contact: "",
    image: ApoiosImage10,
    link: "",
    scrollType: "",
  },
  {
    id: " ",
    title: "Decor Flor",
    text: "",
    Contact: "",
    image: ApoiosImage11,
    link: "",
    scrollType: "",
  },
];
