export const Home_Main_Content = {
  HomeMainIntro1: {
    PT: "A ",
    GB: "",
    FR: "",
    ES: "",
  },
  HomeMainName: {
    PT: "artiset",
    GB: "artiset",
    FR: "artiset",
    ES: "artiset",
  },
  HomeMainIntro2: {
    PT: "é uma associação de Artistas Plásticos que tem como missão o apoio, divulgação e promoção actividade artística na região de Setúbal.",
    GB: "is an association of Plastic Artists whose mission is to support, disseminate and promote artistic activity in the region of Setúbal.",
    FR: "est une association d'artistes plasticiens dont la mission est de soutenir, diffuser et promouvoir l'activité artistique dans la région de Setúbal.",
    ES: "es una asociación de Artistas Plásticos que tiene como misión apoyar, difundir y promover la actividad artística en la región de Setúbal.",
  },
};
