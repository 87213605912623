import React from "react";
import "./artiset.css";
//import EventosOngoing from "./EventosOngoing.js";
//import EventosComing from "./EventosComing.js";
//import EventosArchive from "./EventosArchive.js";
/*import {
  Eventos_Menu_Options,
  Eventos_Menu_Options_Translate,
  Main_Menu_Options_Translate,
} from "./configs/MenuBarConfig.js";
*/
import { ExposicoesList } from "./configs/EventosConfig.js";

function EventosOngoingList(props) {
  let EventosOngoingList = props.events_list;

  return (
    <div className="ContentContentRowWrap">
      {EventosOngoingList.map((EventosOngoingList, i) => (
        <img className="EvOngImage" src={EventosOngoingList.image} alt="" />
      ))}
    </div>
  );
}

class Exposicoes extends React.Component {
  constructor(props) {
    super(props);

    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    const d = Date.UTC(year, month, day);

    this.state = {
      EventosOngoingList: ExposicoesList.filter(
        (x) => Date.UTC(x.date_end.year, x.date_end.month, x.date_end.day) > d
      ).sort((a, b) =>
        Date.UTC(a.date_begin.year, a.date_begin.month, a.date_begin.day) >
        Date.UTC(b.date_begin.year, b.date_begin.month, b.date_begin.day)
          ? 1
          : -1
      ),
    };
  }

  render(props) {
    return (
      <div id="Exposicoes" className="ContentFrameLight">
        <div className="ContentTitle">
          Exposições
          {/*  {
            this.props.menu_options_translate[this.props.menu_name][
              this.props.language
            ]
          }*/}
        </div>
        <EventosOngoingList events_list={this.state.EventosOngoingList} />
      </div>
    );
  }
}

export default Exposicoes;
