import footerImg from "./images/footer/turquesa_limao.png";
import Consulta_cliente from "./images/Consulta_de_Cliente.png"
import Abertura_Cliente from "./images/Abertura_de_Cliente.png"
import Alteracao_Cliente from "./images/Alteracao_de_Cliente.png"

export const Footer_Translate = {
  text: {
    PT: "Gostou deste site? É natural, é um site Turquesa Limão. Fale connosco: turquesalimao@gmail.com",
    GB: "Did you like this site? It's natural, it's a Turquesa Limão site. Talk to us: turquesalimao@gmail.com",
    FR: "Vous avez aimé ce site ? C'est naturel, c'est un site Turquesa Limão. Contactez-nous: turquesalimao@gmail.com",
    ES: "¿Te gustó este site? Es natural, es un site Turquesa Limão. Hablanos: turquesalimao@gmail.com",
  },
  image: {
    PT: footerImg,
    GB: footerImg,
    FR: footerImg,
    ES: footerImg,
  },
};



export const Quem_Somos_Translate = {
  QuemSomosUm: {
    PT: "A ARTISET existe, como órgão associativo de Artistas Plásticos, desde 1991. e tem como missão fundamental a divulgação e a promoção da actividade artística da região de Setúbal, bem como a assistência aos associados, no que respeita à amostragem e integração da respectiva produção artística.",
    GB: "ARTISET exists, as an associative body of Plastic Artists, since 1991. and its fundamental mission is the dissemination and promotion of artistic activity in the Setúbal region, as well as the assistance to associates, with regard to sampling and integration of the respective artistic production.",
    FR: "ARTISET existe, en tant que corps associatif d'Artistes Plasticiens, depuis 1991. et sa mission fondamentale est la diffusion et promotion de l'activité artistique dans la région de Setúbal, ainsi que la assistance aux associés, en matière d'échantillonnage et de l'intégration de la production artistique respective.",
    ES: "ARTISET existe, como cuerpo asociativo de Artistas Plásticos, desde 1991. y su misión fundamental es la difusión y promoción de la actividad artística en la región de Setúbal, así como la asistencia a los asociados, en lo que respecta a la toma de muestras y integración de la respectiva producción artística.",
  },
  QuemSomosDois: {
    PT: "É membros da Conf. Port. das Colectividades de Cultura Recreio e Desporto.",
    GB: "It is a member of the Conf. Port. of the Recreation and Sport Culture Collectivities.",
    FR: "Il est membre de la Conf. Port. des Collectivités Loisir et Sport Culture.",
    ES: "Se trata de miembros de la Conf. Puerto. de las Colectividades de Cultura Recreativa y Deportiva.",
  },
  QuemSomosTres: {
    PT: "NIF: 505 429 810",
    GB: "NIF: 505 429 810",
    FR: "NIF: 505 429 810",
    ES: "NIF: 505 429 810",
  },
  QuemSomosQuatro: {
    PT: "IBAN: PT50 0033 0000 4520 3768796 05",
    GB: "IBAN: PT50 0033 0000 4520 3768796 05",
    FR: "IBAN: PT50 0033 0000 4520 3768796 05",
    ES: "IBAN: PT50 0033 0000 4520 3768796 05",
  },
};

export const images = {
img_Consulta_cliente : Consulta_cliente,
img_Abertura_Cliente : Abertura_Cliente,
img_Alteracao_Cliente : Alteracao_Cliente

}