import React from "react";
import "./artiset.css";
import {
  QuemSomos_Assembleia_List,
  QuemSomos_Direcao_List,
  QuemSomos_ConselhoFiscal_List,
  QuemSomos_Direcao_Translate,
} from "./configs/QuemSomosDirecaoConfig";

function OrgaoDirecaoList(props) {
  const OrgaoList = props.direcaoOrgaoList;

  return (
    <div className="ContentContentColumn">
      {OrgaoList.map((OrgaoList) => (
        <div className="DirecaoListItem">
          <span className="DirecaoCargo">
            {props.direcaoOrgaoTranslate[OrgaoList.Cargo][props.language]}
            :&nbsp;
          </span>
          <span className="DirecaoNome">{OrgaoList.Nome}</span>
        </div>
      ))}
    </div>
  );
}

class QuemSomosDirecao extends React.Component {
  render(props) {
    return (
      <div id="QuemSomosDirecao" className="ContentFrameLight">
        <div className="ContentTitle">
          {
            QuemSomos_Direcao_Translate["TituloDirecaoGeral"][
              this.props.language
            ]
          }
        </div>
        <div className="ContentContentRow">
          <div className="ContentContentColumnBox">
            <div className="ContentSubTitle">
              {
                QuemSomos_Direcao_Translate["TituloAssembleia"][
                  this.props.language
                ]
              }
            </div>

            <OrgaoDirecaoList
              direcaoOrgaoList={QuemSomos_Assembleia_List}
              language={this.props.language}
              direcaoOrgaoTranslate={QuemSomos_Direcao_Translate}
            />
          </div>

          <div className="ContentContentColumnBox">
            <div className="ContentSubTitle">
              {
                QuemSomos_Direcao_Translate["TituloDirecao"][
                  this.props.language
                ]
              }
            </div>

            <OrgaoDirecaoList
              direcaoOrgaoList={QuemSomos_Direcao_List}
              language={this.props.language}
              direcaoOrgaoTranslate={QuemSomos_Direcao_Translate}
            />
          </div>

          <div className="ContentContentColumnBox">
            <div className="ContentSubTitle">
              {
                QuemSomos_Direcao_Translate["TituloConcelhoFiscal"][
                  this.props.language
                ]
              }
            </div>

            <OrgaoDirecaoList
              direcaoOrgaoList={QuemSomos_ConselhoFiscal_List}
              language={this.props.language}
              direcaoOrgaoTranslate={QuemSomos_Direcao_Translate}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default QuemSomosDirecao;
