export const QuemSomos_Direcao_Translate = {
  TituloDirecaoGeral: {
    PT: "Corpos Sociais 2022/2024",
    GB: "Social Bodies 2022/2024",
    FR: "Organismes sociaux 2022/2024",
    ES: "Órganos Sociales 2022/2024",
  },
  TituloAssembleia: {
    PT: "Assembleia Geral",
    GB: "General Assembly",
    FR: "Assemblée Générale",
    ES: "Asamblea General",
  },
  TituloDirecao: {
    PT: "Direção",
    GB: "Management",
    FR: "Direction",
    ES: "Dirección",
  },
  TituloConcelhoFiscal: {
    PT: "Conselho Fiscal",
    GB: "Fiscal Council",
    FR: "Conseil fiscal",
    ES: "Consejo Fiscal",
  },
  Presidente: {
    PT: "Presidente",
    GB: "President",
    FR: "Président",
    ES: "Presidente",
  },
  VicePresidente: {
    PT: "Vice-Presidente",
    GB: "Vice President",
    FR: "Vice-Président",
    ES: "Vicepresidente",
  },
  Relator: {
    PT: "Relator",
    GB: "Reporter",
    FR: "Reporter",
    ES: "Reportero",
  },
  Suplente: {
    PT: "Suplente",
    GB: "Spair",
    FR: "Suppléant",
    ES: "Suplente",
  },
  Tesoureiro: {
    PT: "Tesoureiro",
    GB: "Treasurer",
    FR: "Trésorier",
    ES: "Tesorero",
  },
  Secretario: {
    PT: "Secretário",
    GB: "Secretary",
    FR: "Secrétaire",
    ES: "Secretario",
  },
  Vogal: {
    PT: "Vogal",
    GB: "Vowel",
    FR: "Voyelle",
    ES: "Vocal",
  },
  Nome: {
    PT: "Nome",
    GB: "Name",
    FR: "Nom",
    ES: "Nombre",
  },
  Contato: {
    PT: "Contato",
    GB: "Contact",
    FR: "Contact",
    ES: "Contacto",
  },
};

export const QuemSomos_Assembleia_List = [
  {
    Cargo: "Presidente",
    Nome: "Fernando Pinho",
    Contato: "936 435 210",
  },
  {
    Cargo: "VicePresidente",
    Nome: "Maria Dália Vale Rego",
    Contato: "919 890 470",
  },
  {
    Cargo: "Relator",
    Nome: "Cecília Dias Gomes",
    Contato: "919 031 340",
  },
  {
    Cargo: "Suplente",
    Nome: "Teresa Maria da Silva R. Marques de Oliveira Pinto",
    Contato: "917 275 348",
  },
  {
    Cargo: "Suplente",
    Nome: "João Lourenço Martins de Oliveira Pinto",
    Contato: "916 861 615",
  },
];

export const QuemSomos_Direcao_List = [
  {
    Cargo: "Presidente",
    Nome: "Ana Margarida Rodrigues Mendes",
    Contato: "918 928 573",
  },
  {
    Cargo: "VicePresidente",
    Nome: "António José Madureira Pais",
    Contato: "929 183 787",
  },
  {
    Cargo: "Tesoureiro",
    Nome: "Luz Ângela Corso Sarmiento",
    Contato: "960 222 008",
  },
  {
    Cargo: "Secretario",
    Nome: "João Domingos Rato Viegas",
    Contato: "966 132 447",
  },
  {
    Cargo: "Vogal",
    Nome: "Hugo da Silva",
    Contato: "966 788 209",
  },
  {
    Cargo: "Suplente",
    Nome: "Victor Estrela Santos",
    Contato: "962 626 940",
  },
  {
    Cargo: "Suplente",
    Nome: "Carlos Branco",
    Contato: "938778384",
  },
  {
    Cargo: "Suplente",
    Nome: "Carmo Candeias",
    Contato: "961 706 085",
  },
];

export const QuemSomos_ConselhoFiscal_List = [
  {
    Cargo: "Presidente",
    Nome: "Maria Manuela Azevedo Pereira",
    Contato: "968 914 171",
  },
  {
    Cargo: "VicePresidente",
    Nome: "Aníbal Madeira",
    Contato: "937 056 400",
  },
  {
    Cargo: "Secretario",
    Nome: "Joana Soveral",
    Contato: "917 263 940",
  },
  {
    Cargo: "Suplente",
    Nome: "Valentim Gutu",
    Contato: "968 548 418",
  },
];
