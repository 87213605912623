import React from "react";
import "./artiset.css";
import { QuemSomos_Contatos_Translate } from "./configs/QuemSomosContatosConfig";
import { FaPhone, FaEnvelope } from "react-icons/fa";

class QuemSomosContatos extends React.Component {
  render(props) {
    return (
      <div id="QuemSomosContatos" className="ContentFrameLight">
        <div className="ContentTitle">
          {
            QuemSomos_Contatos_Translate["QuemSomos_Contatos_Title"][
              this.props.language
            ]
          }
        </div>
        <div className="ContentContentRow">
          <div className="QuemSomosContactMap">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d780.3457370834387!2d-8.893792370779487!3d38.524939798726855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1943a7900c55cb%3A0x6d20c21de06b7631!2sCulture%20house!5e0!3m2!1sen!2spt!4v1647203846458!5m2!1spt!2spt"
              width="100%"
              height="100%"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              title="artiset location"
            ></iframe>
          </div>
          <div className="ContentContentColumn">
            <p>
              {
                QuemSomos_Contatos_Translate["QuemSomos_Contatos_Text1"][
                  this.props.language
                ]
              }
            </p>
            <p>
              {
                QuemSomos_Contatos_Translate["QuemSomos_Contatos_Address1"][
                  this.props.language
                ]
              }
              <br></br>
              {
                QuemSomos_Contatos_Translate["QuemSomos_Contatos_Address2"][
                  this.props.language
                ]
              }
              <br></br>
              {
                QuemSomos_Contatos_Translate["QuemSomos_Contatos_Address3"][
                  this.props.language
                ]
              }
              <br></br>
              {
                QuemSomos_Contatos_Translate["QuemSomos_Contatos_Address4"][
                  this.props.language
                ]
              }
            </p>

            <p>
              {
                QuemSomos_Contatos_Translate["QuemSomos_Contatos_Text2"][
                  this.props.language
                ]
              }
            </p>
            <p>
              <FaPhone />{" "}
              {
                QuemSomos_Contatos_Translate["QuemSomos_Contatos_Telephone"][
                  this.props.language
                ]
              }
              <br></br> <FaEnvelope />
              {
                QuemSomos_Contatos_Translate["QuemSomos_Contatos_Email"][
                  this.props.language
                ]
              }
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default QuemSomosContatos;
