import HomeDestaqueImg1 from "./images/HomeDestaque1.jpg";
import HomeDestaqueImg2 from "./images/HomeDestaque2.jpg";
import HomeDestaqueImg3 from "./images/HomeDestaque3.jpg";
import HomeDestaqueImg4 from "./images/HomeDestaque4.jpg";
import HomeDestaqueImg5 from "./images/HomeDestaque5.jpg";

export const HomeDestaque_Items = [
  {
    id: "HomeDestaque1",
    image: HomeDestaqueImg1,
  },
  {
    id: "HomeDestaque2",
    image: HomeDestaqueImg2,
  },
  {
    id: "HomeDestaque3",
    image: HomeDestaqueImg3,
  },
  {
    id: "HomeDestaque4",
    image: HomeDestaqueImg4,
  },
  {
    id: "HomeDestaque5",
    image: HomeDestaqueImg5,
  },
];
