import React from "react";
import "./artiset.css";
import HomeDestaque from "./HomeDestaque";
import Exposicoes from "./EventosOngoing.js";

class MainHome extends React.Component {
  render(props) {
    return (
      <div
        id="MainHome"
        className="ContentOuterFrameColumn"
        style={
          this.props.showMenu === "MainHome"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <div className="ContentFixHeaderMenu"></div>
        <div className="ContentOuterFrameRow">
          <div className="ContentSideMarginLeft"></div>
          <div className="ContentMidle">
            <HomeDestaque language={this.props.language} />
            <Exposicoes language={this.props.language} />
          </div>
          <div className="ContentSideMarginRight"></div>
        </div>
      </div>
    );
  }
}

export default MainHome;
