import React from "react";
import { Slide } from "react-slideshow-image";
import HomeDestaqueSlideContent from "./HomeDestaqueSlideContent";

import { HomeDestaque_Items } from "./configs/HomeDestaqueConfig.js";

const HomeDestaqueSlideShow = (props) => {
  const HomeDestaqueItems = HomeDestaque_Items;

  return (
    <div>
      <Slide easing="ease">
        {HomeDestaqueItems.map((HomeDestaqueItems, i) => (
          <div className="each-slide">
            <HomeDestaqueSlideContent HomeDestaqueItem={HomeDestaqueItems} />
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default HomeDestaqueSlideShow;
